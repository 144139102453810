import React, { createContext, Dispatch, useState } from "react";
import { useLocation } from "react-router-dom";


export const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {

  const location = useLocation().pathname;

  let oldLang = location.split("/")[1];
  if (oldLang !== "pl" && oldLang !== "en") {
    oldLang = "none"
  }


  const [lang, setLang] = useState(oldLang);

  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};