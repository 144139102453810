import "./Modal.css";

function Modal(props) {
  const closeModalHandler = () => {
    props.setIsModalOpen(!props.isModalOpen);
  };

  return (
    <div className={"modal__container" + (props.isModalOpen ? " active" : "")}>
      <div className="modal__closing-div" onClick={closeModalHandler}></div>
      <div className="modal__main-wrapper">
        <p className="modal__content">Thank you for your message!</p>
        <p className="modal__content">We will contact you soon.</p>
      </div>
    </div>
  );
}

export default Modal;
