import React from 'react';
import './CareerDescription.css';

export default function CareerDescription({data}) {
    return (
        <div className='careerDescription__container' >
            <div className='careerDescription__content' >
                    <p className='careerDescription__text' >
                    {data.text}
                    </p>
            </div>
        </div>
    )
}
