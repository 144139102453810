import React from "react";
import "./OtherProjectsAboutSection.css";
import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";

function OtherProjectsAboutSection({data}) {
  const [inViewFirst, setInViewFirst] = useState(false);

  return (
    <section className="OtherProjects-about-section__container">
      <div className="OtherProjects-about-section__content OtherProjects-about-section__content--right">
        <h2 className="OtherProjects-about-section__page-title OtherProjects-about-section__page-title--right">
        {data.title}
        </h2>
        <InView onChange={setInViewFirst}>
          <p
            className={
              "OtherProjects-about-section__text OtherProjects-about-section__text--left" +
              (inViewFirst ? " active" : "")
            }
          >
           {data.text}
          </p>
        </InView>
      </div>
    </section>
  );
}

export default OtherProjectsAboutSection;
