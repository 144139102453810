import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { LanguageProvider } from './contexts/LanguageContext';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <LanguageProvider>
    <App />
    </LanguageProvider>
    </BrowserRouter>,
  document.getElementById('root')
);