import React, { useEffect } from "react";
import Hero from "../../components/Common/Hero/Hero";
import OtherProjectsAboutSection from "../../components/OtherProjectsComponents/AboutSection/OtherProjectsAboutSection";
import OtherProjectsInProgressSection from "../../components/OtherProjectsComponents/InProgressSection/OtherProjectsInProgressSection";
import heroImg from "./zdj_otherprojects.jpg";

export default function OtherProjectsPage({data}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero img={heroImg} headline={data.hero.headline} />
      <OtherProjectsAboutSection data={data.about}/>
      <OtherProjectsInProgressSection data={data.progress}/>
    </div>
  );
}
