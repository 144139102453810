import "./OtherProjectsInProgressSection.css";
import React from "react";

function OtherProjectsInProgressSection({data}) {

  return (
    <section className="inProgress-section__container" >
      <div className="inProgress-section__content">
      </div>
      <div className="inProgress-section__slogan-wrapper">
        <p className="inProgress-section__slogan">
        {data.text}
        </p>
      </div>
    </section>
  );
}

export default OtherProjectsInProgressSection;
