import React from "react";
import "./CareerCompanyValues.css";

const DisplayCompanyValues = ({data}) => {
  return data.map(({ headline, text }) => {
    return (
      <div className="careerCompanyValues__content" key={headline}>
        <h3 className="careerCompanyValues__headline">{headline}</h3>
        <p className="careerCompanyValues__text">{text}</p>
      </div>
    );
  });
};

export default function CareerCompanyValues({data}) {
  return (
    <section className="careerCompanyValues__container">
      <div className="careerCompanyValues__content">
        <h2 className="careerCompanyValues__Sectionheadline  ">
        {data.title}
        </h2>
      </div>
      <DisplayCompanyValues data = {data.data}/>
    </section>
  );
}
